<template>
  <v-container fluid class="hero">
    <v-row v-if="todolists.records.length > 0">
      <v-col cols="12" md="6">
        <br />
        <v-list class="forlist">
          <v-list-item-group>
            <v-list-item v-for="i in todolists.records" :key="i.id">
              <template v-slot:default="{ active }">
                <v-list-item-action>
                  <to-do-edit :formType="'markDone'" :fromId="i.id" />
                </v-list-item-action>

                <v-list-item-content>
                  <v-list-item-title>
                    <h2>{{ i.description }}</h2>
                    <br />
                    Created at {{ i.createdAt | getFormattedYYYYMMDD }}
                  </v-list-item-title>
                </v-list-item-content>
              </template>
            </v-list-item>
          </v-list-item-group>

          <!---------------------------------------------------->

          <!-- <v-dialog v-model="toDoCreatedialog" width="800">
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" color="green" fab elevation="1"
                ><v-icon>mdi-plus</v-icon></v-btn
              >
            </template>

            <v-card>
              <v-card-title class="text-h5 grey lighten-2">
                <h1>Create To Do Item</h1>
              </v-card-title>

              <v-card-text>
                <to-do-edit
                  :assignedTo="me.id"
                  :assignedToName="me.firstName + ' ' + me.lastName"
                />
              </v-card-text>

              <v-divider></v-divider>

              <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn color="primary" text @click="toDoCreatedialog = false">
                  Close
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog> -->
        </v-list>
      </v-col>
      <v-col
        cols="12"
        md="6"
        class="absolute-center"
        v-if="
          $vuetify.breakpoint.md ||
          $vuetify.breakpoint.lg ||
          $vuetify.breakpoint.xl
        "
      >
        <v-avatar class="" size="200" tile>
          <v-img src="../../assets/FairwageLogo.png"></v-img>
        </v-avatar>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col cols="12" md="6">
        <h1>There is nothing yet for you to do :)</h1>
        <!-- <v-dialog v-model="toDoCreatedialog" width="800">
          <template v-slot:activator="{ on, attrs }">
            <v-col class="text-right">
              <v-btn
                v-bind="attrs"
                v-on="on"
                dark
                color="green"
                fab
                elevation="0"
                ><v-icon>mdi-plus</v-icon></v-btn
              >
            </v-col>
          </template>

          <v-card>
            <v-card-title class="text-h5 grey lighten-2">
              <h1>Add an item</h1>
            </v-card-title>

            <v-card-text>
              <shopping-edit
                :assignedTo="me.id"
                :assignedToName="me.firstName + ' ' + me.lastName"
              />
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn color="primary" text @click="toDoCreatedialog = false">
                Close
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog> -->
      </v-col>
      <v-col
        cols="12"
        md="6"
        class="absolute-center"
        v-if="
          $vuetify.breakpoint.md ||
          $vuetify.breakpoint.lg ||
          $vuetify.breakpoint.xl
        "
      >
        <v-avatar class="" size="200" tile>
          <v-img src="../../assets/FairwageLogo.png"></v-img>
        </v-avatar>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { gql } from "apollo-boost";
import ToDoEdit from "../../components/Base/ToDoEdit.vue";
export default {
  components: { ToDoEdit },
  name: "ToDoList",
  mounted() {
    this.$emit("update:pageName", this.$options.name);
  },
  data() {
    return {
      show: false,
      showMore: false,
      toDoCreatedialog: false,

      selectedItem: 1,
      newItems: [],

      isLoading: false,
    };
  },
  apollo: {
    me: gql`
      query {
        me {
          id
          firstName
          lastName
        }
      }
    `,

    todolists: {
      query: gql`
        query Todolists($limit: Int!, $skip: Int!, $query: JSON!) {
          todolists(limit: $limit, skip: $skip, query: $query) {
            records {
              id
              description
              done
              assignedTo
              assignedToName
              createdBy
              createdByName
              createdAt
              updatedAt
            }
            count
          }
        }
      `,
      fetchPolicy: "network-only",
      variables() {
        const self = this;
        const limit = 0;
        const skip = 0;
        return {
          limit: limit,
          skip: skip,
          query: { assignedTo: this.me.id },
        };
      },

      watchLoading(isLoading, countModifier) {
        this.isLoading = isLoading;
      },
    },

    users: {
      query: gql`
        query Users($limit: Int, $query: JSON!) {
          users(limit: $limit, query: $query) {
            records {
              id
              firstName
              lastName
              role
              consultant
              profilePicture
            }
          }
        }
      `,
      variables() {
        return {
          limit: 999999999,
          query: { consultant: this.me.id }, //{},
        };
      },
      watchLoading(isLoading, countModifier) {
        // this.isLoading = isLoading;
      },
      result({ data }, key) {
        this.newItems = data.users.records;
        if (this.newItems.length > 0) {
          this.selectedItem = this.newItems[0];
        }
      },
    },
  },
  methods: {},
  computed: {},
};
</script>

<style scoped>
.herso {
  background: url("../../assets/landing.png") no-repeat center center fixed !important;
  background-size: cover;
  height: 100vh;
  width: 100vw;
}

.v-sheet.v-card {
  cbackground: #6dd984;
  border-radius: 10px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.theme--light.v-list {
  background: #6dd984;
}

.card-header {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 20px;
}

.select-card {
  padding: 10px 30px;
  min-height: 60px;
  position: relative;
}

.top-sect .v-btn {
  width: calc(100% - 67px);
  min-height: 70px;
}

.top-sect .v-btn__content {
  color: #eeecf1 !important;
  font-weight: 700 !important;
  font-size: 20px !important;
  letter-spacing: 0.75px;
}

.top-sect .v-card__actions {
  position: absolute;
  right: 10px;
  top: 10px;
}

.top-sect .v-icon.v-icon {
  color: #ffffff;
}

.select-list {
  margin-top: 10px;
}

.ico-point {
  font-size: 30px;
}

.app-btn {
  height: auto;
  min-height: 10px;
  background-color: #fff;
  min-width: 0;
  border-radius: 12px;
  padding: 0;
}

.app-btn .v-btn__content {
  display: block !important;
  padding: 10px;
}

.v-application .secondary.app-btn {
  background-color: #f1f3f6 !important;
  border-color: #f1f3f6 !important;
  color: #3f3d56;
  box-shadow: none;
}
.v-application .primary.app-btn {
  background-color: #6dd984 !important;
  border-color: #6dd984 !important;
  color: #fff;
}

.app-btn .v-btn__content i {
  margin-bottom: 10px;
  border: 2px solid;
  border-radius: 50%;
}

.theCaleSect,
.btn-group {
  text-align: center;
}

/*/////////////////////////*/
.forCale {
  background-color: white;
  padding: 10px !important;
  overflow: hidden;
}

.forCale .names {
  background: #a5a6f6;
  border-radius: 10px;
}

.v-btn:not(.v-btn--round).v-size--default.app-btn {
  height: auto;

  min-width: auto;
  padding: 10px 10px !important;
}

/*/////////////////////////*/

.name-selecter-parent {
  border: 4px solid#A5A6F6;
  margin-bottom: 30px;
  border-radius: 10px;
  background: #5d5fef;
}

.name-selecter > button {
  width: 100%;
  position: relative;
}

.name-selecter > button i {
  position: absolute;
  right: 0;
}

/*history*/
.forlist.theme--light.v-list {
  background: #fff;
}

.forlist.theme--light.v-list .v-list-item {
  border-bottom: 1px solid #dadada;
}
</style>
