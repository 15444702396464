var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"hero",attrs:{"fluid":""}},[(_vm.todolists.records.length > 0)?_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('br'),_c('v-list',{staticClass:"forlist"},[_c('v-list-item-group',_vm._l((_vm.todolists.records),function(i){return _c('v-list-item',{key:i.id,scopedSlots:_vm._u([{key:"default",fn:function({ active }){return [_c('v-list-item-action',[_c('to-do-edit',{attrs:{"formType":'markDone',"fromId":i.id}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_c('h2',[_vm._v(_vm._s(i.description))]),_c('br'),_vm._v(" Created at "+_vm._s(_vm._f("getFormattedYYYYMMDD")(i.createdAt))+" ")])],1)]}}],null,true)})}),1)],1)],1),(
        _vm.$vuetify.breakpoint.md ||
        _vm.$vuetify.breakpoint.lg ||
        _vm.$vuetify.breakpoint.xl
      )?_c('v-col',{staticClass:"absolute-center",attrs:{"cols":"12","md":"6"}},[_c('v-avatar',{attrs:{"size":"200","tile":""}},[_c('v-img',{attrs:{"src":require("../../assets/FairwageLogo.png")}})],1)],1):_vm._e()],1):_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('h1',[_vm._v("There is nothing yet for you to do :)")])]),(
        _vm.$vuetify.breakpoint.md ||
        _vm.$vuetify.breakpoint.lg ||
        _vm.$vuetify.breakpoint.xl
      )?_c('v-col',{staticClass:"absolute-center",attrs:{"cols":"12","md":"6"}},[_c('v-avatar',{attrs:{"size":"200","tile":""}},[_c('v-img',{attrs:{"src":require("../../assets/FairwageLogo.png")}})],1)],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }